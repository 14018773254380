import Metodo from './components/Metodo.js';

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
	<div>
		<h1 className="text-center">El desarrollador de la web acepta <a href="http://bot.jrios.us/donativo.html">donativos</a></h1>
		<h3 className="text-center">Para conocer más sobre éste método, únete en <a href="https://www.spartanbrokers.com">www.spartanbrokers.com</a></h3>
		<Metodo />
		<p className="text-center">
			El desarrollador de esta web no se hace responsable de las ganancias o pérdidas que estas operativas te puedan causar.<br/>
			El 75% de los minoristas que operan en CFDs pierden dinero.<br/>
			Rentabilidades pasadas no garantizan rentabilidades futuras.
		</p>
	</div>
  );
}

export default App;
