import React, { useState, useCallback, useEffect } from 'react';
import MyInput from './MyInput.js';
import MySelect from './MySelect.js';
import Candle from './Candle.js';
import Pivot from './Pivot.js';
import Operativas from './Operativas.js';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Metodo() {
	const [first,setFirst] = useState(true);
	const [spread,setSpread] = useState("2");
	const [canal,setCanal] = useState("20");
	const [index,setIndex] = useState("32");
	const indexData = [
			{id:0,  name:"Manual"},
			{id:32, name:"GER40"},
			{id:28, name:"NSDQ100"},
			{id:43, name:"EUSTX50"},
			{id:38, name:"HKG50"},
			{id:36, name:"JPN225"},
			{id:34, name:"ESP35"},
			{id:33, name:"AUS200"},
			{id:31, name:"FRA40"},
			{id:30, name:"UK100"},
			{id:29, name:"DJ30"},
			{id:27, name:"SPX500"},
			{id:26, name:"China50"},
		];
	
	const [auto,setAuto] = useState(true);
	const [candle,setCandle]  = useState({
		open: "",
		close: "",
		high: "",
		low: "",
	});
	const [pivot,setPivot] = useState([]);
	const [operativas,setOperativas] = useState([]);

	const etoroUpdate = useCallback(i => {
		if(i !== "0") {
			setAuto(true);
			fetch('/candles/desc.json/OneDay/2/'+i)
			.then(res => res.json())
			.then((data) => {
				var now = new Date();
				if(i === 32) {
					now.setHours(now.getHours() + 2);
				}
				var day = ("0" + now.getDate()).slice(-2);
				var month = ("0" + (now.getMonth() + 1)).slice(-2);
				var today = now.getFullYear() + "-" + (month) + "-" + (day);
				var c = data.Candles[0].Candles[0];
				if( c.FromDate.startsWith(today) ) {
					c = data.Candles[0].Candles[1];
				}
				setCandle({
					open: c.Open, 
					close: c.Close, 
					high: c.High, 
					low: c.Low
				});
			})
			.catch(console.log);
		} else {
			setAuto(false);
		}
	}, [setCandle, setAuto]);

	useEffect(() => {
		var c = parseFloat(candle.close);
		var h = parseFloat(candle.high);
		var l = parseFloat(candle.low);
		
		var _spread = parseFloat(spread);
		var _canal = parseFloat(canal);
		
		var P = ( h + l + c) / 3;
        var R1 = P + P - l;
        var R2 = P + h - l;
        var R3 = h + 2 * (P - l);
        var S1 = P + P - h;
        var S2 = P + l - h;
        var S3 = l - 2 * (h - P);
		
		setPivot([
			{name: "R3", value: R3},
			{name: "R2", value: R2},
			{name: "R1", value: R1},
			{name: "P", value: P},
			{name: "S1", value: S1},
			{name: "S2", value: S2},
			{name: "S3", value: S3},
		]);
		
		var rR1 = Math.floor(R1)
		var rR2 = Math.floor(R2)
		var rR3 = Math.floor(R3)
		
		var rS1 = Math.ceil(S1)
		var rS2 = Math.ceil(S2)
		var rS3 = Math.ceil(S3)
		
		setOperativas([
			{name: "R3", stop: rR3-_spread-(2*_canal), tasa: rR3-_spread-_canal, profit: rR3-_spread, canal: R3-R2},
			{name: "R2", stop: rR2-_spread-(2*_canal), tasa: rR2-_spread-_canal, profit: rR2-_spread, canal: R2-R1},
			{name: "R1", stop: rR1-_spread-(2*_canal), tasa: rR1-_spread-_canal, profit: rR1-_spread, canal: R1-P},
			{name: "S1", stop: rS1+_spread+(2*_canal), tasa: rS1+_spread+_canal, profit: rS1+_spread, canal: P-S1},
			{name: "S2", stop: rS2+_spread+(2*_canal), tasa: rS2+_spread+_canal, profit: rS2+_spread, canal: S1-S2},
			{name: "S3", stop: rS3+_spread+(2*_canal), tasa: rS3+_spread+_canal, profit: rS3+_spread, canal: S2-S3},
		]);
		
	}, [candle, spread, canal, setPivot, setOperativas]);

	useEffect(() => {
		if(first) {
			setFirst(false);
			etoroUpdate(index);
		}
	}, [index, first, setFirst, etoroUpdate]);
	
	const handleIndexChange = useCallback(i => {
		setIndex(i);
		etoroUpdate(i);
	}, [setIndex, etoroUpdate]);
	
	const handleSpreadChange = useCallback(i => {
		setSpread(i);
	}, [setSpread]);
	
	const handleCanalChange = useCallback(i => {
		setCanal(i);
	}, [setCanal]);
	
	const handleCandleChange = useCallback(i => {
		setCandle(i);
	}, [setCandle]);

	return (
		<Container fluid="md">
			<Card body className="mt-2">
			<Row>
				<Col xs={12} sm={4} className="mt-1">
					<MySelect label="Índice" value={index} options={indexData} onChange={handleIndexChange} />
				</Col>
				<Col xs={6} sm={4} className="mt-1">
					<MyInput label="Spread" value={spread} onInputChange={handleSpreadChange} />
				</Col>
				<Col xs={6} sm={4} className="mt-1">
					<MyInput label="Canal" value={canal} onInputChange={handleCanalChange} />
				</Col>
			</Row>
			</Card>
			<Candle readOnly={auto} candle={candle} onCandleChange={handleCandleChange} />
			<Row>
				<Col md={2}>
					<Pivot pivot={pivot} />
				</Col>
				<Col md={10}>
					<Operativas operativas={operativas} minCanal={canal*2}/>
				</Col>
			</Row>
		</Container>
	);
}

export default Metodo;
