import React from 'react';
import Form from 'react-bootstrap/Form'
import FloatingLabel from "react-bootstrap/FloatingLabel";

function MySelect(props) {
	
	function handleChange(e) {
		var v = e.target.value;
		props.onChange(v);
	};
	
	return (
		<FloatingLabel label={props.label}>
			<Form.Select value={props.value} onChange={handleChange}>
				{props.options.map((e, key) => {
					return <option key={e.id} value={e.id}>{e.name}</option>;
				})}
			</Form.Select>
		</FloatingLabel>
	);
}

export default MySelect;