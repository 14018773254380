import React from 'react';
import MyInput from './MyInput.js';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Candle(props) {
	
	function handleOpenChange(v) {
		props.onCandleChange({
			open: v,
			close: props.candle.close,
			high: props.candle.high,
			low: props.candle.low,
		});
	};
	
	function handleCloseChange(v) {
		props.onCandleChange({
			open: props.candle.open,
			close: v,
			high: props.candle.high,
			low: props.candle.low,
		});
	};
	
	function handleHighChange(v) {
		props.onCandleChange({
			open: props.candle.open,
			close: props.candle.close,
			high: v,
			low: props.candle.low,
		});
	};
	
	function handleLowChange(v) {
		props.onCandleChange({
			open: props.candle.open,
			close: props.candle.close,
			high: props.candle.high,
			low: v,
		});
	};
	
	return (
		<Card body className="mt-2">
			<Row>
				<Col xs={6} sm={3} className="mt-1">
					<MyInput readOnly={props.readOnly} label="Open" value={props.candle.open} onInputChange={handleOpenChange} />
				</Col>
				<Col xs={6} sm={3} className="mt-1">
					<MyInput readOnly={props.readOnly} label="Close" value={props.candle.close} onInputChange={handleCloseChange} />
				</Col>
				<Col xs={6} sm={3} className="mt-1">
					<MyInput readOnly={props.readOnly} label="High" value={props.candle.high} onInputChange={handleHighChange} />
				</Col>
				<Col xs={6} sm={3} className="mt-1">
					<MyInput readOnly={props.readOnly} label="Low" value={props.candle.low} onInputChange={handleLowChange} />
				</Col>
			</Row>
		</Card>
	);
}

export default Candle;
