import React from 'react';
import Form from 'react-bootstrap/Form'
import FloatingLabel from "react-bootstrap/FloatingLabel";

function MyInput(props) {
	
	function handleChange(e) {
		var v = e.target.value;
		v = v.trim();
		v = v.replace(",",".");
		var i = v.lastIndexOf(".");
		if(i >= 0) {
			v = v.substr(0,i).replaceAll(".","") + v.substr(i);
		}
		props.onInputChange(v);
	};
	
	return (
		<FloatingLabel label={props.label}>
			<Form.Control readOnly={props.readOnly} type="text" placeholder={props.label} value={props.value} onChange={handleChange} />
		</FloatingLabel>
	);
}

export default MyInput;