import React from 'react';

import Table from 'react-bootstrap/Table';

function Pivot(props) {
	return (
		<div className="mt-2">
			<h5>Pivotes</h5>
			<Table bordered hover size="sm" className="text-center">
				<tbody>
					{props.pivot.map((e, key) => {
						return (
							<tr key={key} className={e.name}>
								<td className="fw-bold">{e.name}</td>
								<td>{Math.round(e.value*100)/100}</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</div>
	);
}

export default Pivot;