import React from 'react';

import Table from 'react-bootstrap/Table';

function Operativas(props) {
	var now = new Date();
	var day = ("0" + now.getDate()).slice(-2);
	var month = ("0" + (now.getMonth() + 1)).slice(-2);
	var today = (day) + "/" + (month);
	
	function getCanalClass(e) {
		if(e.canal >= props.minCanal) {
			return "profit";
		} else {
			return "stop";
		}
	}
	
	return (
		<div className="mt-2">
			<h5>Operativas</h5>
			<Table bordered hover size="sm" className="text-center">
				<thead>
					<tr>
						<th>{today}</th>
						<th className="stop">Stop</th>
						<th className="tasa">Tasa</th>
						<th className="profit">Profit</th>
						<th>Distancia</th>
					</tr>
				</thead>
				<tbody>
					{props.operativas.map((e, key) => {
						return (
							<tr key={key} className={e.name}>
								<td className="fw-bold">{e.name}</td>
								<td>{Math.round(e.stop*100)/100}</td>
								<td>{Math.round(e.tasa*100)/100}</td>
								<td>{Math.round(e.profit*100)/100}</td>
								<td className={getCanalClass(e)}>{Math.round(e.canal*10)/10}</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</div>
	);
}

//{Math.round(e.canal*100)/100}
export default Operativas;